import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../common/app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class GtaService {

  apiGstrServiceUrl = AppConfigService.ApiGstrServiceUrl;
  constructor(private _httpClient:HttpClient) { }

  UploadMultiEinvoice(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.apiGstrServiceUrl + '/PdfReading/MultiUploadBOEFile',formdata);    
  }
  GetGtaRequest(currentPage : number, pageSize :number ): Observable<any> {
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/PdfReading/GetGtaRequest?currentPage=' + currentPage + '&pageSize=' + pageSize);
  }
  downloadGtaFile(guid,fileDescription,organizationId :number): Observable<any> {
    
    return this._httpClient.get(
      this.apiGstrServiceUrl + '/PdfReading/DownLoadFile?guid='+ guid + '&fileDescription='+fileDescription + '&organizationId=' + organizationId, {
      responseType: 'blob'
    });
  }
}
