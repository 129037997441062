import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { templateName } from 'src/app/shared/Constants';
import { getCurrentDateTimeForFileName } from 'src/app/shared/helpers/date-time-helper';
import { downloadFile } from 'src/app/shared/helpers/file-helper';
import { FileTemplates } from 'src/app/shared/models/get/get-md-beow-upload/get-file-history';
import { GetUploadFileService } from 'src/app/shared/services/get/get-srv-boew-upload/get-upload-file.service';
import { GlobalSrvConstantsService } from 'src/app/shared/services/global/global-constants/global-srv-constants.service';
import { PostFileUploadService } from 'src/app/shared/services/post/pst-srv-boew-upload/post-file-upload.service';
import { UploadDocumentService } from 'src/app/shared/services/uploadDoument/upload-document.service';
import { GlobalApiService } from 'src/app/shared/services/global/global-api/global-api.service';
import { Gstr2aService } from 'src/app/shared/services/Returns/gstr2-a/gstr2a.service';

@Component({
    selector: 'app-sub-reconcile-force-match-upload',
    templateUrl: './sub-reconcile-force-match-upload.component.html',
    styleUrls: ['./sub-reconcile-force-match-upload.component.css'],
})
export class SubReconcileForceMatchUploadComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    isIntentFm : boolean = false;
    organizationId :number;
    selectedFileName: any;
    fileDesc: string;
    fileName: string;
    file: any;
    isUploading = false;
    templateSelected: string;
    templateList: FileTemplates[] = [];
    templateSelectedName: string;
    fileSizeLimit: number = 2000;
    fileSizeLimitMsg: string;
    fileSize: number;
    reconType: string = "GSTR2A";
    tabName : string = null;
    uploadFile = new FormGroup({
        // fileDescription: new FormControl(),
        // templateID: new FormControl(),
        invoice: new FormControl(['']),
    });
    isDownloadTemplateButtonEnable = true;
    isAutoForceMatch = false;

    @Output() fileUploadedEvent = new EventEmitter<string>();
    // tslint:disable-next-line: variable-name
    constructor(
        private _snackBar: MatSnackBar,
        private globalApiService: GlobalApiService,
        private _postUploadFile: PostFileUploadService,
        private _getUploadFile: GetUploadFileService,
        private uploadDocumentService: UploadDocumentService,
        @Inject(MAT_DIALOG_DATA) public dialogForceMatch: any,
        private gstr2aService: Gstr2aService,
        public dialogFMRef: MatDialogRef<SubReconcileForceMatchUploadComponent>
    ) {
        dialogFMRef.disableClose = true;
        this.organizationId = Number(GlobalApiService.getCurrentOrganizationID());
    }

    ngOnInit() {
        this.getUploadTemplates();
        this.getOrganizationConfig();
        if (this.dialogForceMatch == undefined || this.dialogForceMatch.type == undefined) {
            this.reconType = "GSTR2A";
        }
        else {
            this.reconType = this.dialogForceMatch.type;
        }
        if(this.dialogForceMatch != undefined && this.dialogForceMatch.tabName != undefined )
        this.tabName = this.dialogForceMatch.tabName;
        else
        this.tabName = null;
    }

    downloadTemplate() {
        this.isDownloadTemplateButtonEnable = false;
        let reportType = this.dialogForceMatch.reportType.startsWith("AutoRecon")? "AutoRecon" : "Manual";
        let requestType = this.dialogForceMatch.reportType.startsWith("AutoRecon")? this.dialogForceMatch.type: this.dialogForceMatch.reportType.split(" ")[1];
        this.uploadDocumentService
            .getForceMatchDocumentTemplates(this.dialogForceMatch.actionType,reportType,this.tabName,requestType)
            .subscribe((data: any) => {
                const fileName =
                    templateName.DocumentUpload +
                    '_' +
                    getCurrentDateTimeForFileName() +
                    '.zip';
                downloadFile(data, fileName);
            });
        this.isDownloadTemplateButtonEnable = true;
    }

    onFileSelected(event: any) {
        const fileTemp = event.target.files[0];
        this.file = fileTemp;
        this.fileName = fileTemp.name;
        this.fileSize = event.target.files[0].size;
        if (this.templateSelectedName != undefined) {
            this.validateFileSize();
        }
        this.uploadFile.get('invoice').setValue(this.file);
        if(this.isAutoForceMatch){
            this._snackBar.open('If you are uploading Auto Recon file for ForceMatch , please remove Summary sheet before uploading.', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['success-snackbar']
            });
        }
    }

    validateFileSize() {
        if (this.templateSelectedName == 'GSTR1 - Standard Template') {
            this.fileSizeLimit = 11000;
            this.fileSizeLimitMsg =
                'File size must be less than or equal to 11 MB';
        } else {
            this.fileSizeLimit = 2000;
            this.fileSizeLimitMsg =
                'File size must be less than or equal to 2 MB';
        }
        if (this.fileSize != null) {
            if (Math.round(this.fileSize / 1024) > this.fileSizeLimit) {
                this._snackBar.open(this.fileSizeLimitMsg, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['alert-snackbar'],
                });

                this.fileName = '';
                this.fileInput.nativeElement.value = '';
                this.fileSize = null;
                return;
            }
        }
    }

    getUploadTemplates() {
        const fileTemplates: FileTemplates = {} as FileTemplates;
        fileTemplates.value = '0';
        fileTemplates.template = '2ARecon - Force Match Template';
        this.templateList.push(fileTemplates);

        // this._getUploadFile.getReconTemplates().subscribe(
        //     (data) => {
        //         if ('200'.localeCompare(data.status) === 0) {
        //             if (data.data == null || data.data === undefined) {
        //                 return;
        //             } else if (data.data.count === 0) {
        //                 return;
        //             } else {
        //                 data.data.forEach((element) => {
        //                     const fileTemplates: FileTemplates = {} as FileTemplates;
        //                     fileTemplates.value = element.templateId;
        //                     fileTemplates.template = element.templateName;
        //                     this.templateList.push(fileTemplates);
        //                 });
        //             }
        //         }
        //     },
        //     (error) => {
        //         this._snackBar.open(
        //             'Error | Status: ' + error.status + ' | ' + error.error,
        //             'OK',
        //             {
        //                 duration: GlobalSrvConstantsService.SNACKBARDURATION,
        //                 panelClass: ['danger-snackbar'],
        //             }
        //         );
        //     }
        // );
    }

    templateChange(templateValue: any) {
        if (templateValue == null) {
            this.templateSelected = null;
            this.templateSelectedName = null;
        } else {
            this.templateSelected = templateValue.value;
            this.templateSelectedName = templateValue.template;
            this.validateFileSize();
        }
    }

    onSubmit() {
        if (this.fileName == null) {
            this._snackBar.open('Please Select File', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        }
         else if (
            (this.fileName.split('.').pop().trim() !== 'xlsx' && this.fileName.split('.').pop().trim() !== 'XLSX')
            && (this.fileName.split('.').pop().trim() !== 'xls' && this.fileName.split('.').pop().trim() !== 'XLS')
            && (this.fileName.split('.').pop().trim() !== 'xml' && this.fileName.split('.').pop().trim() !== 'XML')
            && (this.fileName.split('.').pop().trim() !== 'csv' && this.fileName.split('.').pop().trim() !== 'CSV')) {
            this._snackBar.open('Please Select Correct File Format', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        } 
        else {
            const formData = new FormData();
            formData.append('reconFile', this.uploadFile.get('invoice').value);
            formData.append('actionType', this.dialogForceMatch.actionType);
            formData.append('requestID', this.dialogForceMatch.requestID);
            formData.append('autoReconType', this.reconType);
            formData.append('tabName', this.tabName);
            if(this.dialogForceMatch.reportType == "AutoRecon"){
                formData.append('reportType', this.dialogForceMatch.reportType);
                formData.append('isIntentFm', "" + this.isIntentFm + "");
            }
            else
                formData.append('reportType', this.dialogForceMatch.reportType.split(" ")[1]);
            this.isUploading = true;
            this._postUploadFile.postReconData(formData)
                .subscribe
                (
                    data => {
                        let flag = (this.reconType == "GSTR2B")? "2B" : "2A";
                        if ('200'.localeCompare(data.status) === 0) {
                            if(this.dialogForceMatch.reportType === "AutoRecon" && data.data === '200'){
                                console.log(this.dialogForceMatch.reportType)
                                console.log(data.data)
                                this._snackBar.open('File Uploaded Successfully. Please refresh the grid manually for updated results. \n Review the forcematch status by downloading the error file.', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                            }
                            else if (this.dialogForceMatch.reportType === "AutoRecon" && (data.data === '204' || data.data === '205')) {
                                this._snackBar.open('File Uploaded Successfully. Please refresh the grid manually for updated results. \n Review the forcematch status by downloading the error file.', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                            }
                            else{
                                this._snackBar.open('File Uploaded Successfully', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                            }
                            this.fileUploadedEvent.emit('1');
                            this.isUploading = false;
                        }
                        if ('208'.localeCompare(data.status) === 0) {
                            this._snackBar.open('Recon for GSTIN('+ this.dialogForceMatch.gstin + ') already processed.', 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['warning-snackbar']
                            });
                            this.fileUploadedEvent.emit('1');
                            this.isUploading = false;
                        }
                        if ('500'.localeCompare(data.status) === 0) {
                            this._snackBar.open('Reuploading failed for GSTIN('+ this.dialogForceMatch.gstin+').', 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['warning-snackbar']
                            });
                            this.fileUploadedEvent.emit('1');
                            this.isUploading = false;
                        }
                    },
                    error => {
                        this._snackBar.open('Error | Status: Error uploading recon template.', 'OK', {
                            duration: GlobalSrvConstantsService.SNACKBARDURATION,
                            panelClass: ['danger-snackbar']
                        });
                        this.isUploading = false;
                    }
                );
            this.dialogFMRef.close(true);
            this.uploadFile.markAsPristine();
            this.uploadFile.markAsUntouched();
            this.uploadFile.updateValueAndValidity();
            this.uploadFile.reset();
            this.fileName = null;
            this.fileInput.nativeElement.value = '';
        }
    }
    dismiss(){
        this.dialogFMRef.close();
    }
    isIntentFmOnChange(){
        this.isIntentFm = !this.isIntentFm;
    }    
    getOrganizationConfig(){
        this.gstr2aService.GetSTR2BMergeFlag().subscribe((data) => {
          if (data.data != null) {
            if (data.data.enableAutoReconForceMatch == true) {
              this.isAutoForceMatch = true;
            }
            else {
              this.isAutoForceMatch = false;
            }
          }
          else {
            this.isAutoForceMatch = false;
          }
        });
      }
}
