import { EntityGSTINSelected } from 'src/app/shared/models/global/boew-eway/get-boew-eway-search';

export class gstRefundInputDetails {
    EntityGstin  : string;
    ApplicationType : string;
    FromPeriod : string;
    ToPeriod : string;
    type? : string;
    isOutwards? : boolean;
    higherRates? : string;
    lowerRates? : string;
    source? : string;
}

export class GenerateDocSave {
    tableADetails  : TableADetails[] =[];
    tableBDetails : TableBDetails[]=[];
}

export class TableADetails {
    entityGstin  : string;
    fromReturnPeriod : string;
    toReturnPeriod : string;
    organizationID:number;
    particularsID:number;
    particulars:string;
    amount:number;
    refundApplicationType:number;
    subType:string;
}

export class TableBDetails {
    entityGstin  : string;
    fromReturnPeriod : string;
    toReturnPeriod : string;
    organizationID:number;
    refundApplicationType:number;
    subType:string;
    particularsID:number;
    particulars:string;
    igst:number;
    cgst:number;
    sgst:number;
    cess:number;
    amount:number;
}
export class OutwardsInwardsSummaryTableDetails {
    particulars:string;
    count : number;
    amount:number;
}
export class ValidationStatusTableDetails {
    supplyType:string;
    validationStatus : string;
    errorFileID:number;
}

export class GenerateDocStatusDetails {
    message:string;
    status : string;
    refundApplicationType:number;
}
export class Table7Details {
    entityGstin  : string;
    fromReturnPeriod : string;
    toReturnPeriod : string;
    organizationID:number;
    refundApplicationType:number;
    subType:string;
    particularsID:number;
    particulars:string;
    reason:string;
    netamount:number;
    adjustment:number;
    amount:number;
}

export class ClaimDetailsModel {
    EntityGstin  : string;
    ApplicationType : string;
    FromPeriod : string;
    ToPeriod : string;
    type : string;
    isOutwards : boolean;
    higherRates : string;
    lowerRates : string;
    particularsID:number;
    source :string;
}

export class ValidationInputDetails {
    RequestID: number;
    ReconType: string;
}

export class TableCashBalanceDetails {
    organizationID: number;
    entityGSTIN: string;
    period: string
    header: string;
    tax: number;
    interest: number;
    penalty: number;
    fee: number;
    other: number;
    total: number;
}
export class OriginalTableCashBalanceDetails {
    organizationID: number;
    entityGSTIN: string;
    period: string
    header: string;
    tax: number;
    interest: number;
    penalty: number;
    fee: number;
    other: number;
    total: number;
}

export class CashLedgerPdfInputModel {
    EntityGstin: string;
    RequestID: number;
    FromPeriod: string;
    ToPeriod: string;
}
