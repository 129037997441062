import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { BoLogOutComponent } from './bo-log-out/bo-log-out.component';
import { BoLoginComponent } from './bo-login/bo-login.component';
import { BoewUploadComponent } from './boew-upload/boew-upload.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';

const routes: Routes = [
    { path: '', component: AppComponent, canActivate: [AuthGuard] },
    {path:'upload',component:BoewUploadComponent,canActivate:[AuthGuard]},
    {path:'logout',component:BoLogOutComponent},
    { path: 'login', component: BoLoginComponent },
    { path: 'ei', loadChildren: () => import('./einvoice/einvoice.module').then(m => m.EinvoiceModule),canActivate: [AuthGuard] },
    { path: 'ewaybill', loadChildren: () => import('./eway/eway.module').then(m => m.EwayModule),canActivate: [AuthGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),canActivate: [AuthGuard] },
    { path: 'returns', loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule),canActivate: [AuthGuard] },
    { path: 'ledger', loadChildren: () => import('./ledger/ledger.module').then(m => m.LedgerModule),canActivate: [AuthGuard] },
    { path: 'gstr6', loadChildren: () => import('./gstr6/input-service-distribution.module').then(m => m.InputServiceDistributionModule),canActivate: [AuthGuard] },
    { path: 'gstinvalidation', loadChildren: () => import('./gstin-validation/gstin-validation.module').then(m => m.GstinValidationModule),canActivate: [AuthGuard] },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate: [AuthGuard] },
    { path: 'dataretrieval', loadChildren: () => import('./data-retreival/data-retreival.module').then(m => m.DataRetreivalModule),canActivate: [AuthGuard] },
    { path: 'qrcode', loadChildren: () => import('./qr-code/qr-code.module').then(m => m.QrCodeModule),canActivate: [AuthGuard] },
    { path: 'masters', loadChildren: () => import('./Masters/masters.module').then(m => m.MastersModule),canActivate: [AuthGuard] },
    { path: 'taxnotice', loadChildren: () => import('./taxnotice/taxnotice.module').then(m => m.TaxNoticeModule),canActivate: [AuthGuard] },
    { path: 'unauthorized',component:UnauthorizedComponent,canActivate:[AuthGuard]},
    { path: 'gstrefund', loadChildren: () => import('./gst-refund-automate/gst-refund-automate.module').then(m => m.GstRefundAutomateModule)},
    {path: 'gta', loadChildren: () => import('./gta/gta.module').then(m => m.GtaModule),canActivate: [AuthGuard]},
    { path: 'moowr', loadChildren: () => import('./moowr-report/moowr-report.module').then(m => m.MoowrReportModule)},
    {path: 'hsn', loadChildren: () => import('./hsn-cls/hsn-cls.module').then(m => m.hsnClsModule),canActivate: [AuthGuard]}
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
