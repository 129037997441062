import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
      Renderer2,
        Inject
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { authConfig } from '../auth.config';
import { OrganizationDetail } from '../shared/models/get/get-md-global/get-organization-detail';
import { GlobalApiService } from '../shared/services/global/global-api/global-api.service';
import { SrvAuthService } from '../shared/services/global/global-auth-service/srv-auth.service';
import { Roles } from '../shared/Constants';
import { RevokeAccessTokenReq } from '../shared/models/global/acc-token/access-token-req';
import { QrcodeService } from '../shared/services/qrcode/qrcode.service';
import { DOCUMENT } from '@angular/common';
import { HsnClsService } from '../shared/services/hsn-cls/hsn-cls.service';

@Component({
    selector: 'app-bo-nav',
    styleUrls: ['./bo-nav.component.css'],
    templateUrl: './bo-nav.component.html'
})
export class BoNavComponent implements OnInit, OnChanges {
    onLogIn = false;
    isLoggedIn = false;
    revokeAccessToken:RevokeAccessTokenReq = new RevokeAccessTokenReq();
    UserName: string;
    OrganizationDetail: OrganizationDetail[];
    userProfile: string;
    userProfileName:string;
    orgName:string;
    viveEnabled = false;
    hsnEnabled = false;

    isHandset = false;

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    @ViewChild('drawer', { static: false }) drawer: MatSidenav;

    constructor(
        private authService: SrvAuthService,
        private oauthService: OAuthService,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private globalsrv: GlobalApiService,
        private _qrCodeService: QrcodeService,
        private _hsnClsService :HsnClsService,
         private _renderer2: Renderer2, 
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.authService.oAuthService = this.oauthService;
        this.userProfile = environment.authURL + 'Manage';
        this.authService.oAuthService.configure(authConfig);
        this.authService.oAuthService.tokenValidationHandler = new JwksValidationHandler();
        this.authService.oAuthService.loadDiscoveryDocumentAndTryLogin();
        this.authService.oAuthService.setupAutomaticSilentRefresh();

        // Load Discovery Document and then try to login the user
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.onLogIn = false;
                if ('/'.localeCompare(val.url) === 0) {
                    if (this.authService.oAuthService.hasValidIdToken()) {
                        this.globalsrv.getOrganizationDetail().then(() => {
                            this.userProfileName = this.givenName.substring(0,2).toUpperCase();
                            this.UserName =
                                this.givenName ;
                                // ' | ' +
                                // GlobalApiService.getCurrentOrganizationName();
                            this.isLoggedIn = true;
                            this.orgName = GlobalApiService.getCurrentOrganizationName();
                            this.globalsrv.getUserRoles(Number(GlobalApiService.getCurrentOrganizationID())).subscribe((res: any) => {
                                this.globalsrv.userroles = res.data;
                            })
                            this._qrCodeService.getQRCIsViveEnabled().subscribe((data: any) => {
                                if(data.data === 1)
                                {
                                    this.globalsrv.IsViveEnabled = true;
                                    this.viveEnabled = this.globalsrv.IsViveEnabled;
                                }
                               
                                else
                                {
                                    this.globalsrv.IsViveEnabled = false;
                                    this.viveEnabled = this.globalsrv.IsViveEnabled;
                                }
                             });
                             this._hsnClsService.getHsnClsEnabled().subscribe((data: any) => {
                                if(data.data === 1)
                                {
                                    this.globalsrv.IsHsnClsEnabled = true;
                                    this.hsnEnabled = this.globalsrv.IsHsnClsEnabled;
                                }
                               
                                else
                                {
                                    this.globalsrv.IsHsnClsEnabled = false;
                                    this.hsnEnabled = this.globalsrv.IsHsnClsEnabled;
                                }
                                
                               
                            })
                        });
                        
                    } else {
                        this.drawer.close();
                        this.UserName = this.givenName;
                        this.userProfileName = this.givenName.substring(0,2).toUpperCase();
                         this.orgName = GlobalApiService.getCurrentOrganizationName();
                        this.isLoggedIn = false;
                    }
                } else if (val.url.startsWith('/login')) {
                    this.onLogIn = true;
                    if (!this.authService.oAuthService.hasValidIdToken()) {
                        this.drawer.close();
                        this.UserName = this.givenName;
                        this.userProfileName = this.givenName.substring(0,2).toUpperCase();
                        this.orgName = GlobalApiService.getCurrentOrganizationName();
                        this.isLoggedIn = false;
                    }
                } else {
                    this.userProfileName = this.givenName.substring(0,2).toUpperCase();
                    this.orgName = GlobalApiService.getCurrentOrganizationName();
                    this.UserName =
                        this.givenName 
                        // ' | ' +
                        // GlobalApiService.getCurrentOrganizationName();
                }

                if (this.isHandset) {
                    this.drawer.close();
                }
            }
        });

        this.isHandset$.subscribe(result => {
            this.isHandset = result;
        });
    }

    ngOnInit(): void {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.globalsrv.InactiveSession.subscribe((isInactive) => {
            if (isInactive) {
                this.logOut();
            }
        })
        if (this.authService.oAuthService.hasValidIdToken()) {
            this.userProfileName = this.givenName.substring(0,2).toUpperCase();
            this.orgName = GlobalApiService.getCurrentOrganizationName();
            this.UserName =
                this.givenName 
                // ' | ' +
                // GlobalApiService.getCurrentOrganizationName();
            this.isLoggedIn = true;
           
            let script = this._renderer2.createElement('script');
            script.type = `text/javascript`;
            script.text = `
            function getCookie(name) {
                var value = "; " + document.cookie;
                var parts = value.split("; " + name + "=");
                if (parts.length == 2) {
                    return true;
                }
            }
        
            function reloadOTBanner() {
        
                var otConsentSdk = document.getElementById("onetrust-consent-sdk");
                if (otConsentSdk) {
                    otConsentSdk.remove();
                }
        
                if (window.OneTrust != null) {
                    OneTrust.Init();
        
                    setTimeout(function() {
                        OneTrust.LoadBanner();
        
                        var toggleDisplay = document.getElementsByClassName(
                            "ot-sdk-show-settings"
                        );
        
                        for (var i = 0; i < toggleDisplay.length; i++) {
                            toggleDisplay[i].onclick = function(event) {
                                event.stopImmediatePropagation();
                                window.OneTrust.ToggleInfoDisplay();
                            };
                        }
                    }, 1000);
                }
            }

            //SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
            function clearDup() {
                var sec = document.getElementById("ot-sdk-cookie-policy")
                var tally = [];
                for (var i = sec.length - 1; i >= 0; i--) {
                    if (tally[sec[i].firstChild.innerText] === undefined) {
                        tally[sec[i].firstChild.innerText] = 1;
                    } else {
                        //console.log(i,sec[i].firstChild.innerText);
                        sec[i].remove();
                        //return true;
                    }
                }
                //return false;
            }
            
                    function OptanonWrapper() { }
            `;
            
            this._renderer2.appendChild(this._document.body, script);
            this._qrCodeService.getQRCIsViveEnabled().subscribe((data: any) => {
                if(data.data === 1)
                {
                    this.globalsrv.IsViveEnabled = true;
                    this.viveEnabled = this.globalsrv.IsViveEnabled;
                }
               
                else
                {
                    this.globalsrv.IsViveEnabled = false;
                    this.viveEnabled = this.globalsrv.IsViveEnabled;
                }
                
               
            })
            this._hsnClsService.getHsnClsEnabled().subscribe((data: any) => {
                if(data.data === 1)
                {
                    this.globalsrv.IsHsnClsEnabled = true;
                    this.hsnEnabled = this.globalsrv.IsHsnClsEnabled;
                }
               
                else
                {
                    this.globalsrv.IsHsnClsEnabled = false;
                    this.hsnEnabled = this.globalsrv.IsHsnClsEnabled;
                }
                
               
            })
            this.globalsrv.getUserRoles(Number(GlobalApiService.getCurrentOrganizationID())).subscribe((res: any) => {
                this.globalsrv.userroles = res.data;
            })
        } else {
            this.isLoggedIn = false;
        }
        //this.CheckEnable();
    }
    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        // Add '${implements OnChanges}' to the class.
        if (this.authService.oAuthService.hasValidIdToken()) {
            this.userProfileName = this.givenName.substring(0,2).toUpperCase();
            this.orgName = GlobalApiService.getCurrentOrganizationName();
            this.UserName =
                this.givenName 
                // ' | ' +
                // GlobalApiService.getCurrentOrganizationName();
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }
    checkIsRolesExist(roles: string) {
        if (roles != "" && roles != undefined) {

            var rolesRequired = roles.split('!');
            let count = 0;
            rolesRequired.forEach((role) => {
                if (this.globalsrv.userroles.findIndex(x => x == role) != -1) {
                    count = count + 1;
                }
            })
            return rolesRequired.length == count

        }
    }

    //  ngAfterViewInit()
    // {
    //    let script = this._renderer2.createElement('script');
    // script.type = `text/javascript`;
    // script.text = `
    //         function OptanonWrapper() { }
    // `;
    // this._renderer2.appendChild(this._document.body, script);
    // }
    
    CheckEnable(){
       
        return this.globalsrv.IsViveEnabled == true;
        
   }
   CheckHSNClsEnable(){
       
    return this.globalsrv.IsHsnClsEnabled == true;
    
}
    get Roles() { return Roles; }
    public logOut() {
        if (this.authService.oAuthService.hasValidIdToken()) {
            this.revokeAccessToken.ClientId = environment.clientId;
            this.revokeAccessToken.Token = this.globalsrv.getCurrentAccessToken();
            this.globalsrv.revokeAccessToken(this.revokeAccessToken).subscribe((res)=>{
              this.signOut()
            },err =>{this.signOut()});
        }
    }
    
    public signOut()
    {
        localStorage.setItem("login", "false");
        this.authService.oAuthService.logOut();
        this.userProfileName = this.givenName.substring(0,2).toUpperCase();
            this.UserName = this.givenName;
            this.isLoggedIn = false;
            sessionStorage.removeItem('Organization');
            localStorage.removeItem('orgSwitch');  
     }
    get givenName() {
        if (!this.authService.oAuthService.hasValidIdToken()) {
            return '';
        }
        const claims: any = this.authService.oAuthService.getIdentityClaims();
        if (!claims) {
            return '';
        }
        return claims.name;
    }

    getOrganization() {
        // this.OrganizationDetail = {} as OrganizationDetail[];
        this.globalsrv.changeOrganizationDetail().then(() => {
            this.OrganizationDetail = {} as OrganizationDetail[];
            this.OrganizationDetail = GlobalApiService.ORGANIZATIONDATA;
        });
    }

    switchOrganization(selectItemName: string, selectedID: string) {
        if (String(selectedID) === GlobalApiService.getCurrentOrganizationID()) {
            return;
        } else {

            if(!(Number(selectedID) > 0) )
            return;

            const deatils: OrganizationDetail = {} as OrganizationDetail;
            deatils.organizationId = Number(selectedID);
            deatils.organizationName = selectItemName;
           
           this.globalsrv.switchOrganization(Number(selectedID)).subscribe((res: any)=>{
            sessionStorage.clear();
                sessionStorage.setItem('Organization', JSON.stringify(deatils));          
                localStorage.setItem('orgSwitch',selectedID);  
                    
                    this.UserName =
                    this.givenName 
                    // ' | ' +
                    // GlobalApiService.getCurrentOrganizationName();
                    this.userProfileName = this.givenName.substring(0,2).toUpperCase();
                    this.orgName = GlobalApiService.getCurrentOrganizationName(); 
                    
                    //window.location.href = res.data
                    //this.router.navigate(['/login']);  

                    var url = new URL(res.data);
                    if(url.host !=window.location.host)
                    {
                        //window.location.href = res.data + '/login';
                        var DifferentWindow= window.open(res.data);
                    } 
                    else               
                        this.router.navigate(['/login']);  
                    
            },err =>{
                localStorage.removeItem('orgSwitch');  
                alert('Some problem occured, logging out. Please retry again.')
                
            this.signOut()
        });
           
          
        }
        this.globalsrv.getUserRoles(Number(selectedID)).subscribe((response: any) => {
            this.globalsrv.userroles = response.data;
        })
    }

    filterOrganization(searchOrg:string){

        if(searchOrg !='')
            this.OrganizationDetail = GlobalApiService.ORGANIZATIONDATA.filter(name=>name.organizationName.toLocaleLowerCase().startsWith(searchOrg.trim().toLocaleLowerCase()));
        else
        this.OrganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    }
}
