export const Roles =
{
    'Returns': 'Returns',
    'Admin': 'Admin',
    'DataUpload': 'Data Upload',
    'SuperAdmin': 'Super Admin',
    'EInvoice': 'E-Invoice',
    'EWayBill': 'E-Way Bill',
    'InternalAdmin': 'InternalAdmin',
    'Reports':'Reports',
    'MaximizeITC':'Maximize ITC',
    'TaxNotice':'Tax Notice',
    'Utilities' : 'Utilities',
    'AuditReadiness' : 'Audit Readiness',
    'VRM' : 'VRM',
    'GSTRefund' : 'GSTRefund'
}

export const ValueConstants =
{
    'DataUploadValue': 'Data Upload',
    'ViewUser': 'View',
    'RemoveUser': 'Delete',
    'Forbidden': '403',
    'AddGstin' : 'AddNewGSTIN',
    'UpdateGstin' : 'UpdateExistingGSTIN',
    'RevokeGstin' : 'RevokeGSTIN',
    'AddGstinSuccess' : '1 Record/s Inserted Successfully',
    'UpdateGstinSuccess' : '1 Record/s Updated Successfully',
    'RevokeGstinSuccess' : 'Revoke done Successfully',
    'GstinAlreadyExists' : 'GSTIN Already Exists'
}
export const IdleTimeOutSettings =
{
    'IdleTime': 1680,
    'TimeOutAfterIdleTime': 60
}
export const possibleSteps = [
    { id: 1, path: "userDetails", name: "Add User Details" },
    { id: 2, path: "userROles", name: "Add User Roles" }
]

export enum DialogTypes {
    Success,
    Error,
    Info,
    Warning,
    Delete,
    Confirmation,
    Update,
    Register,
    IdleTime,
    RevokeToken=10,
    RevokeGstin = 11,
    UploadError = 12,
    RemoveTransactionalData = 13,
    GSTINSessionActivateError = 14,
    GSTINSessionActivate = 15,
    DownloadGSTR1PopUp = 16,
    ShowGSTR1EinvoiceReconInfo = 17,
    cancelEwaybills = 18,
    ForceMatch = 19,
    DeLinkMatch = 20,
    ReconEmail = 21,
    ReconResendEmail = 22,
    HSNUpload = 23,
    DocUpload = 24,
    AutoRecon = 25,
    AutoReconUpload = 26,
    AutoReconEmail = 27,
    B2CSUpload = 28,
    AutoReconDeLinkUpload = 29,
    AutoReconForceUpload = 30,
    AutoReconRequestEmail = 31,
    ViewVendorGSTINs = 32,
    UpdateRemarks = 33,
    GSTR2BConfirmation = 34,
    GstRefundPeriodError= 35,
    GstRefundSelectedPeriodInfo = 36,
    ManualReconVendorGSTINSelection = 37,
    ClaimEligiblePullInfo = 38,
    ReconTypeSelectionForRefund = 39,
    ReconTypeSelectionForRefund_Inwards = 40,
    GSTR1VenderGSTINSelection = 41,
    GSTR1ResetFunctionality = 42,
    LitigationOverWriteResponse = 43,
    LitigationResponsePageInfo = 44,
    LitigationPartAOverWriteInfo = 45,
    IMSAutoRecon = 46,
    IMSAutoReconPopUp = 47,

}

export class Dialog {
    Type: DialogTypes;
    Message: string;
    deleteData:string[];
    AdditionalInfo:any;
    ReportType:string;

}


export enum MasterSource{
    FTP = 1,
    UI = 2,
    API = 3
}

export enum applicationFileType{
    ZIP = 'application/zip',
}

export enum fileExtensions {
    PDF = ".pdf",
    EXCEL = ".xls",
    DOC = ".docx",
    PNG = ".png",
    JPG = ".jpg",
    TEXT = ".txt",
    ZIP = ".zip"
}

export enum templateName{
    DocumentUpload = 'Document_Upload_Templates',
}

export enum Key{
    publicKey = "-----BEGIN PUBLIC KEY-----"
      +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqj3PK907M+8pMhsZUui8"
      +"6k8cfuL2QphfEgqbBpIakKz2vLKBEn03jK0WMhY1lrkJcnAOCTC/6I0fbxfraVa5"
      +"cxxxPav314wVqZaVqqKeKs38g4CO25fV40uzc4c/PGoADkbPTqD/AYnLo1I4m5n5"
      +"AqgJm7Uc5kN8lBT0W+vEI2xiTJe8vDlXXeS9157mF60XenM+oaL9JuGwGVES8mEP"
      +"1JypHhjl91hqBmAqLKyDSZHR9gQIXlLDeUa82F/gcmOhZ5m5QVoSuJ8NtjaxqT7J"
      +"16gQEuJ7kxV/TxaZG6GFU38104vblk4GghX6RMnj0UlTK5xjc19xGDcj/0GQC1aC"
      +"sQIDAQAB"
      +"-----END PUBLIC KEY-----"
}
