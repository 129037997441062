import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config/app-config.service';
import { EmailDetails } from '../../models/common/send-email/send-email';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private httpClient: HttpClient
  ) { }

  commonAPIBaseUrl = AppConfigService.ReportServiceURL + '/api/common/';

  sendEmail(emailDetails: EmailDetails): Observable<any> {
    return this.httpClient.post(this.commonAPIBaseUrl + 'sendemail', emailDetails);
  }

}

