import { Injectable } from '@angular/core';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DownloadGSTR2ARequetViewModel, GetGSTR2AReportViewModel, GetGSTR2ARequetViewModel } from 'src/app/shared/models/global/boew-eway/get-boew-eway-search';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { PrepareReturnsRequestsViewModel } from '../returns-model.service';
import { GetAutoReconUIGrid, MultiReportRequestViewModel } from '../../global/ReportGeneration';
@Injectable({
  providedIn: 'root'
})
export class Gstr2aService {

  constructor(private _httpClient: HttpClient, private snackBar: MatSnackBar, public dialog: MatDialog) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  reportingServiceBaseUrl = AppConfigService.ReportServiceURL;
  getGstinSessionDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetGSTR2ARequest', GetGSTR2ARequetViewModel
    )
  }

    getGstr2AReportDetails(getGSTR2AReportViewModel: DownloadGSTR2ARequetViewModel): Observable<any> {
        // return this._httpClient.post(
        //   this.gstrServiceBaseUrl + '/GSTR2A/GetGSTR2ADownloadReport', getGSTR2AReportViewModel
        // )
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/DataRetrieval/GetDownloadRequest', getGSTR2AReportViewModel
        )
    }

    getGstr2AReportDetailsMerge(getGSTR2AReportViewModel: DownloadGSTR2ARequetViewModel): Observable<any> {
        // return this._httpClient.post(
        //   this.gstrServiceBaseUrl + '/GSTR2A/GetGSTR2ADownloadReport', getGSTR2AReportViewModel
        // )
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/DataRetrieval/GetDownloadRequest', getGSTR2AReportViewModel
        )
    }

  postGstr2ADetails(getGSTR2AReportViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertGSTR2ARequest', getGSTR2AReportViewModel
    )
  }

  postGstr3BDetails(getGSTR2AReportViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR3B/GetGSTR3B', getGSTR2AReportViewModel
    )
  }

  InsertReconcileDetailsWithFile(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertReconcileGSTR2ARequestReport', reconcileRequestModel
    )
  }

  InsertReconcileDetails(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertReconcileGSTR2ARequestReport', reconcileRequestModel
    )
  }
  getReconcileDetails(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetReconcileGSTR2ARequestReport', reconcileRequestModel
    )
  }
  getCombinedReport(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetCombinedReconcileRequestReport', reconcileRequestModel
    )
  }

  generateNewReport(request): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GenerateGST2AReport', request
    )
  }

   generateNewReportwithMerge(request): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/DataRetrieval/GenerateReport', request
        );
   }

  getFilterData(returnType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/FilterData' + '/' + returnType
    );
  }
  getCacheData(returnType: string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/Cache/FilterData' + '/' + returnType
    );
  }

  getVendorCtins(entityGstins,reportedPeriods): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetVendorCtins?entityGstins='+ entityGstins + '&reportedPeriods='+ reportedPeriods
    );
  }
  
  getGstr2BReportDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetGSTR2ARequest', GetGSTR2ARequetViewModel
    )
  }

  Insert6AReconcileDetails(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertReconcileGSTR6ARequestReport', reconcileRequestModel
    )
  }
  get6AReconcileDetails(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetReconcileGSTR6ARequestReport', reconcileRequestModel
    )
  }

  getAll6AReconcileData(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/Get6ARecoData', reconcileRequestModel
    )
  }
  Update6AReconcileDetails(result): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/UpdateReconcileGSTR6AReport',result
    )
  }
  gettrackreturnsResponseDetails(GetGSTR2ARequetViewModel: GetGSTR2ARequetViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR9/GettrackreturnsResponse', GetGSTR2ARequetViewModel
    )
  }

  getReconEmailHistory(reportType,currentPage,pageSize,action:string): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR2A/recon/emailhistory?reportType='+ reportType +'&currentPage='+ currentPage + '&pageSize='+pageSize+ '&actionType=' + action)
  }
  
  sendAutoReconMail(reportType:string, GSTIN:string[], Period:string[],CCEmailID:string,action:string,requestId:number,emailInterval:string,emailIteraton:string,invoiceStatusType:string,isVendorMail:number,ToEmailID:string): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR2A/autorecon/sendmail?GSTIN='+ GSTIN + '&Period=' + Period + '&reportType=' + reportType + '&CCEmailID='+ CCEmailID + '&actionType=' + action+ '&emailInterval='+ emailInterval + '&emailIteraton='+ emailIteraton + '&invoiceStatusType='+ invoiceStatusType+ '&isVendorMail='+ isVendorMail.toString() + '&ToEmailID='+ ToEmailID + '&requestId=' + requestId)
  }
  sendReconMail(requestID,reportType,CCEmailID,emailInterval,emailIteraton,invoiceStatusType,isVendorMail,ToEmailID): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR2A/recon/sendmail?requestId='+ requestID + '&reportType=' + reportType + '&CCEmailID='+ CCEmailID + '&emailInterval='+ emailInterval + '&emailIteraton='+ emailIteraton + '&invoiceStatusType='+invoiceStatusType+ '&isVendorMail='+ isVendorMail.toString() + '&ToEmailID='+ ToEmailID)
  }
  reSendReconMail(requestID,emailReqId): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR2A/recon/resendmail?requestId='+ requestID + '&emailId='+ emailReqId)
  }
  ResetAll(GSTIN:string[], Period:string, Flag:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/UpdateGSTR6ARecoResetAll?GSTIN=' + GSTIN + '&Period=' + Period + '&Flag=' + Flag,
      {
     
      },

    );
  }
  ValidateGSTR6Reconcilation(GSTIN:string[], Period:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/ValidateGSTR6Reconcilation?GSTIN=' + GSTIN + '&Period=' + Period,
      {
      
      },

    );
  }
  ValidateGSTR6ResetFunction(GSTIN:string[], Period:string,DocumentNumber:string[]): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/ValidateGSTR6ResetFunction?GSTIN=' + GSTIN + '&Period=' + Period + '&DocumentNumber=' + DocumentNumber,
      {
      
      },

    );
  }
  ValidateGSTR6ResetAllFunction(GSTIN:string[], Period:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/ValidateGSTR6ResetAllFunction?GSTIN=' + GSTIN + '&Period=' + Period,
      {
       
      },

    );
  }

  Insert2BReconcileDetails(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertReconcileGSTR2BRequestReport', reconcileRequestModel
    )
  }
  getAutoReconDetails(model:any): Observable<any> {
    var data = new GetAutoReconUIGrid();
    data.EntityGSTIN = model.EntityGstin.join(",");
    data.ReportedPeriod = model.ReportedPeriod.Length == 0 ? null : model.ReportedPeriod.join(",");
    data.RequestedBy = model.RequestedBy;
    data.ReturnType = model.ReturnType;
    data.Action = model.Action;
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetITCAutoReconDetails',
      data);
  }

  // downloadAutoReconDetails(model:any): Observable<any> {
  //   return this._httpClient.get(
  //     this.gstrServiceBaseUrl + '/GSTR2A/DownloadITCAutoReconDetails?ReportedPeriod='+ model.ReportedPeriod + '&EntityGSTIN='+ model.EntityGstin + '&RequestedBy='+model.RequestedBy + '&ReturnType='+model.ReturnType)
  // }

  downloadAutoReconDetails(gstinPeriod: MultiReportRequestViewModel): Observable<any> {
    return this._httpClient.post(
        this.gstrServiceBaseUrl + '/ReturnsReport/stdMultiReport',
        gstinPeriod
    );

}

  AcceptAutoRecon(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/AcceptAutoRecon', reconcileRequestModel
    )
  }

  
  AcceptIMSAutoRecon(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/AcceptIMSAutoRecon', reconcileRequestModel
    )
  }
  
  submitCombinedReconReq(gstinPeriod: MultiReportRequestViewModel): Observable<any> {
    console.log(gstinPeriod);
    return this._httpClient.post(
        this.gstrServiceBaseUrl + '/ReturnsReport/CombinedReconReq',gstinPeriod
    );
  }

  GetSTR2BAutoReconFlag(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR2A/GetSTR2BAutoReconFlag'
    )
  }

  GetLastRecon(action): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR2A/GetLastRecon?actionType=' + action
    )
  }

  GetAutoConfig(action): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR2A/GetAutoConfig?actionType=' + action
    )
  }

  InsertTaxCompareDetails(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertTaxCompareRequest', reconcileRequestModel
    )
  }
  getTaxCompareDetails(reconcileRequestModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetTaxCompareRequestReport', reconcileRequestModel
    )
  }
  ValidateGSTR6Reset(GSTIN:string[], Period:string,ActionFlag:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/ValidateGSTR6Reset?GSTIN=' + GSTIN + '&Period=' + Period + '&ActionFlag=' + ActionFlag,
      {
       
      },

    );
  }

AcceptOrPendingAll(GSTIN:string[], Period:string, ActionFlag:string,Flag:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/UpdateGSTR6AAcceptOrPendingAll?GSTIN=' + GSTIN + '&Period=' + Period + '&ActionFlag=' + ActionFlag + '&Flag=' + Flag,
      {
     
      },

    );
  }
  ValidateGSTR6Accept(GSTIN:string[], Period:string, ActionFlag:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/ValidateGSTR6Accept?GSTIN=' + GSTIN + '&Period=' + Period + '&ActionFlag=' + ActionFlag,
      {
      
      },

    );
  }

  SaveDateConfig(date,tolTaxVal,tolTaxableVal,cessVal, action,ignoreSplChr,FTPModeOn,HostName,UserName,Password,DestinationDirectory): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/SaveDateConfig?date=' + date +'&taxableVal='+tolTaxableVal +'&taxVal=' + tolTaxVal +'&actionType=' + action + '&IgnoreSplChr='+ ignoreSplChr
      +'&FTPModeOn='+ FTPModeOn+'&HostName='+ HostName+'&UserName='+ UserName+'&Password='+ Password+'&destinationDirectory='+ DestinationDirectory+'&cessVal='+cessVal,
      {
   
      },

    );
  }

  setAutoRecon(action): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/SetAutoRecon?actionType=' + action,
      {
     
      },

    );
  }

  checkAutoRecon(action): Observable<any>{
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/checkAutoReconStatus?actionType=' + action,
      {
      
      },

    );
  }

  getAutoReconDetailsSummary(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/GetReconDashboard', reconcileRequestModel
    )
  }

  downloadAutoReconDetailsSummary(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/ReturnsReport/DownloadAutoSummaryReport', reconcileRequestModel
    )
  }

  getVendorReconcileDetails(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/getVendorReconcileDetails', reconcileRequestModel
    )
  }
  getReconScheduledEmailHistory(reportType,currentPage,pageSize,action:string): Observable<any> {
    return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR2A/recon/scheduledemailhistory?reportType='+ reportType +'&currentPage='+ currentPage + '&pageSize='+pageSize+ '&actionType=' + action)
  }

  deleteScheduledEmailHistory(requestID,emailType): Observable<any> {
    return this._httpClient.post(this.gstrServiceBaseUrl + '/GSTR2A/recon/deletescheduledemailhistory?requestID='+ requestID + '&emailType='+emailType,
    {
    },)
  }
  
  getScheduledMailHistory(emailtype): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR2A/GetScheduledMailHistory?emailtype='+ emailtype
    );
  }

  InsertReconcile9Details(reconcileRequestModel): Observable<any> {
    console.log(reconcileRequestModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/InsertReconcileGSTR9RequestReport', reconcileRequestModel
    )
  }
  getVendorValidationStatus(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetVendorValidationStatus'
    )
  }

  checkExistingSuccessRequests(getGSTR2AReportViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR2A/checkExistingSuccessRequests', getGSTR2AReportViewModel
    )
  }

  postVendorGSTINList(FileData: any): Observable<any> {
    return this._httpClient.post(
        this.gstrServiceBaseUrl + '/GSTR2A/UploadVendorGSTIN', FileData);
}
GetSTR2BMergeFlag(): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR2A/GetSTR2BMergeFlag'
  )
}
getForceMatchGridDetails(returnType,currentPage,pageSize): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR2A/autorecon/requesthistory?returnType='+ returnType +'&currentPage='+ currentPage + '&pageSize='+pageSize
  );
}
downloadAutoReconReportDetails(gstinPeriod: MultiReportRequestViewModel): Observable<any> {
  return this._httpClient.post(
      this.gstrServiceBaseUrl + '/ReturnsReport/GenerateAutoRecon',
      gstinPeriod
  );

}
}
