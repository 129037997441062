import { HostListener ,ElementRef,Directive} from '@angular/core';

@Directive({
  selector: 'input[max10k]'
})
export class NumberLimitDirective {
  constructor(private _el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this._el.nativeElement.value;
    if (initialValue > 1000000) {
      event.stopPropagation();
      this._el.nativeElement.value = 1000000;
    }
  }
}
